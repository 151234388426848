<template>
  <div class="container">
    <el-descriptions title="用户信息" :column="1">
      <el-descriptions-item label="员工">{{
        adminInfo.name
      }}</el-descriptions-item>
      <el-descriptions-item label="身份">{{
        adminInfo.type
      }}</el-descriptions-item>
      <el-descriptions-item label="账户余额"
        >￥{{ adminInfo.bonus || 0 }}</el-descriptions-item
      >
      <el-descriptions-item label="累计收益"
        >￥{{ adminInfo.bonus_total || 0 }}</el-descriptions-item
      >
    </el-descriptions>

    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="分成收益记录" name="log">
        <el-form :inline="true" class="demo-form-inline">
          <el-form-item label="分成类型">
            <el-select v-model="log_type">
              <el-option label="入账" value="1,5"></el-option>
              <el-option label="扣除" value="2"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="getAdminBonusLogList(1)"
              >查询</el-button
            >
            <el-button
              v-permission="'editAdminBonus'"
              type="warning"
              @click="editShow = true"
              >增扣分成金额</el-button
            >
          </el-form-item>
        </el-form>
        <auto-table
          :DataList="logDataList"
          :option="logOption"
          :total="logTotal"
          @changePage="getAdminBonusLogList"
        >
          <template #type="{ row }">
            <div>
              {{ row.type == 2 ? "扣除" : "入账" }}
            </div>
          </template>
        </auto-table>
      </el-tab-pane>
      <el-tab-pane label="分成机构" name="mec">
        <auto-table
          :DataList="bonusDataList"
          :option="bonusOption"
          :total="bonusTotal"
          @changePage="getAdminBonusList"
        >
          <el-table-column label="操作">
            <div slot-scope="{ row }">
              <el-button
                type="primary"
                size="small"
                @click="openBonus(row, 'add')"
                v-permission="'addBonus'"
                >增加金额</el-button
              >
              <el-button
                type="warning"
                size="small"
                v-permission="'subBonus'"
                @click="openBonus(row, 'sub')"
                >扣除金额</el-button
              >
              <el-button type="danger" size="small" @click="deleteBonus(row)"
                >删除</el-button
              >
            </div>
          </el-table-column>
        </auto-table>
      </el-tab-pane>
      <el-tab-pane label="提现记录" name="cash">
        <auto-table
          :DataList="orderDataList"
          :option="orderOption"
          :total="orderTotal"
          @changePage="getAdminBonusCashOutList"
        >
          <template #status="{ row }">
            <el-tag type="success" size="small" v-if="row.status == 1"
              >未到账</el-tag
            >
            <el-tag type="info" size="small" v-if="row.status == 2"
              >已到账</el-tag
            >
          </template>
        </auto-table>
      </el-tab-pane>
    </el-tabs>

    <el-dialog
      title="设置分成金额"
      :visible.sync="editShow"
      width="30%"
      :close-on-click-modal="false"
    >
      <el-form>
        <el-form-item label="金额" required>
          <el-input
            type="number"
            :min="0"
            v-model="editForm.cash"
            placeholder="请输入金额"
          ></el-input>
        </el-form-item>
        <el-form-item label="金额" required>
          <el-radio v-model="editForm.type" :label="5">增加分成</el-radio>
          <el-radio v-model="editForm.type" :label="2">扣除分成</el-radio>
        </el-form-item>

        <el-form-item label="说明" required>
          <el-input
            type="textarea"
            v-model="editForm.remark"
            placeholder="说明"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary"  @click="editBonus"
          >确 定</el-button
        >
      </span>
    </el-dialog>

    <el-dialog
      title="分成金额"
      :visible.sync="bonusShow"
      width="30%"
      :close-on-click-modal="false"
    >
      <el-form>
        <el-form-item label="金额" required>
          <el-input
            type="number"
            :min="0"
            v-model="bonusForm.cash"
            placeholder="请输入金额"
          ></el-input>
        </el-form-item>
        <el-form-item label="说明" required>
          <el-input
            type="textarea"
            v-model="bonusForm.remark"
            placeholder="说明"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" v-if="bonusMode == 'add'" @click="addBonus"
          >增 加</el-button
        >
        <el-button type="primary" v-else @click="subBonus">扣 除</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeName: "log",
      id: "",
      bonusShow: false,
      editShow: false,
      editForm: {},
      bonusMode: "add",
      curBonsu: {},
      bonusForm: {},
      //admin info
      adminInfo: {},
      //admin bonus
      bonusDataList: [],
      bonusOption: [
        { name: "机构", value: "map.mechanism.mechanism_name" },
        { name: "分成比例", value: "proportion" },
        { name: "累计收益(元)", value: "cash" },
        { name: "创建时间", value: "create_time" },
      ],
      bonusTotal: 0,
      //admin bonus log
      log_type: "1,5",
      logDataList: [],
      logOption: [
        { name: "机构", value: "mechanism_name" },
        { name: "金额(元)", value: "cash" },
        { name: "类型", value: "type", type: "custom" },
        { name: "时间", value: "create_time" },
        { name: "说明", value: "remark" },
      ],
      logTotal: 0,
      //cash out log
      orderDataList: [],
      orderOption: [
        { name: "订单号", value: "flowing_no" },
        { name: "订单名称", value: "title" },
        { name: "金额(元)", value: "amount" },
        { name: "时间", value: "create_time" },
        { name: "状态", value: "status", type: "custom" },
        { name: "备注", value: "rcharge_abstract" },
      ],
      orderTotal: 0,

      handle:JSON.parse(localStorage.getItem("managerInfo"))
    };
  },

  mounted() {
    this.id = this.$route.query.id;
    this.getAdminInfo();
    this.getAdminBonusLogList();
  },

  methods: {
    editBonus(){
       if (this.editForm.type === 2  && this.editForm.cash > this.adminInfo.bonus) {
        this.$message("扣除金额不能大于现有金额");
        return;
      }
      if (!this.editForm.remark) {
        this.$message("请填写说明");
        return;
      }
      this.$axios({
        url: "/user/adminBonusLog/editBonus",
        method: "post",
        data: {
          admin_id: this.id,
          name: this.adminInfo.name,
          cash: this.editForm.cash,
          remark: this.editForm.remark,
          type:this.editForm.type,
          handle_id:this.handle.id,
           handle_name:this.handle.name,
        },
      }).then((res) => {
        this.$message(res.data.message);
        this.editShow = false;
        this.getAdminBonusLogList();
        this.getAdminInfo();
        
      });
    },
    deleteBonus(row) {
      this.$confirm("确定要删除员工的收益分成吗").then((res) => {
        this.$axios({
          url: "/user/adminBonus/delete",
          params: {
            id: row.id,
          },
        }).then((res) => {
          this.$message(res.data.message);
          this.getAdminBonusList();
        });
      });
    },
    addBonus() {
      if (this.bonusForm.cash <= 0) {
        this.$message("请填写金额");
        return;
      }
      if (!this.bonusForm.remark) {
        this.$message("请填写说明");
        return;
      }
      this.$axios({
        url: "/user/adminBonusLog/addBonusByCash",
        method: "post",
        data: {
          admin_id: this.id,
          mechanism_id: this.curBonsu.mechanism_id,
          name: this.curBonsu.name,
          cash: this.bonusForm.cash,
          remark: this.bonusForm.remark,
          handle_id:this.handle.id,
           handle_name:this.handle.name,
        },
      }).then((res) => {
        this.$message(res.data.message);
        this.bonusShow = false;
        this.getAdminInfo();
        this.getAdminBonusList();
      });
    },
    subBonus() {
      if (this.bonusForm.cash > this.curBonsu.bonus) {
        this.$message("扣除金额不能大于现有金额");
        return;
      }
      if (!this.bonusForm.remark) {
        this.$message("请填写说明");
        return;
      }
      this.$axios({
        url: "/user/adminBonusLog/subBonus",
        method: "post",
        data: {
          admin_id: this.id,
          mechanism_id: this.curBonsu.mechanism_id,
          name: this.curBonsu.name,
          cash: this.bonusForm.cash,
          remark: this.bonusForm.remark,
          handle_id:this.handle.id,
           handle_name:this.handle.name,
        },
      }).then((res) => {
        this.$message(res.data.message);
        this.bonusShow = false;
        this.getAdminInfo();
        this.getAdminBonusList();
      });
    },
    openBonus(row, mode) {
      this.curBonsu = row;
      this.bonusMode = mode;
      this.bonusShow = true;
    },

    handleClick(v) {
      switch (v.name) {
        case "mec":
          this.getAdminBonusList();
          break;
        case "log":
          this.getAdminBonusLogList();
          break;
        case "cash":
          this.getAdminBonusCashOutList();
          break;
      }
    },
    //员工信息
    getAdminInfo() {
      this.$axios({
        url: "/authorization/managerUser/findById",
        params: {
          id: this.id,
        },
      }).then((res) => {
        this.adminInfo = res.data.data;
      });
    },
    // 机构收益分成
    getAdminBonusList(v) {
      let page = v || 1;
      this.$axios({
        url: "/user/adminBonus/queryManagerListPage",
        params: {
          admin_id: this.id,
          currentPage: page,
          pageSize: 10,
        },
      }).then((res) => {
        this.bonusDataList = res.data.data.rows;
        this.bonusTotal = res.data.data.total;
      });
    },
    //分成记录
    getAdminBonusLogList(v) {
      let page = v || 1;
      this.$axios({
        url: "/user/adminBonusLog/queryManagerListPage",
        params: {
          admin_id: this.id,
          currentPage: page,
          pageSize: 10,
          types: this.log_type,
        },
      }).then((res) => {
        this.logDataList = res.data.data.rows;
        this.logTotal = res.data.data.total;
      });
    },
    //提现记录
    getAdminBonusCashOutList(v) {
      let page = v || 1;
      this.$axios({
        url: "/user/rechargerecord/queryManagerListPage",
        params: {
          admin_id: this.id,
          currentPage: page,
          pageSize: 10,
          rcharge_type: "transfer",
        },
      }).then((res) => {
        this.orderDataList = res.data.data.rows;
        this.orderTotal = res.data.data.total;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>